import React,{useEffect} from "react";
import { Link } from "react-router-dom";

import client1 from '../../assets/images/client/01.jpg'
import client2 from '../../assets/images/client/02.jpg'
import client3 from '../../assets/images/client/03.jpg'
import client4 from '../../assets/images/client/04.jpg'
import client5 from '../../assets/images/client/05.jpg'
import client6 from '../../assets/images/client/06.jpg'
import client7 from '../../assets/images/client/07.jpg'
import client8 from '../../assets/images/client/08.jpg'
import client9 from '../../assets/images/client/09.jpg'
import client10 from '../../assets/images/client/10.jpg'
import client11 from '../../assets/images/client/11.jpg'
import client12 from '../../assets/images/client/12.jpg'

import * as Icon from 'react-feather'
import {MdKeyboardArrowRight} from '../../assets/icons/vander'

export default function Team(){

    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
    }, []);

    let clientData = [
        {
            image:client1,
            name:'Jack John',
            title:'Designer'
        },
        {
            image:client2,
            name:'Krista John',
            title:'Designer'
        },
        {
            image:client3,
            name:'Roger Jackson',
            title:'Designer'
        },
        {
            image:client4,
            name:'Johnny English',
            title:'Designer'
        },
        {
            image:client5,
            name:'Jack John',
            title:'Designer'
        },
        {
            image:client6,
            name:'Krista John',
            title:'Designer'
        },
        {
            image:client7,
            name:'Roger Jackson',
            title:'Designer'
        },
        {
            image:client8,
            name:'Johnny English',
            title:'Designer'
        },
        {
            image:client9,
            name:'Jack John',
            title:'Designer'
        },
        {
            image:client10,
            name:'Krista John',
            title:'Designer'
        },
        {
            image:client11,
            name:'Roger Jackson',
            title:'Designer'
        },
        {
            image:client12,
            name:'Johnny English',
            title:'Designer'
        },

    ]

    return(
        <div className="container-fluid relative px-3">
            <div className="layout-specing">
                <div className="md:flex justify-between items-center">
                    <h5 className="text-lg font-semibold">Team Members</h5>

                    <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
                        <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"><Link to="/index">Techwind</Link></li>
                        <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight/></li>
                        <li className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white" aria-current="page">Team</li>
                    </ul>
                </div>

                <div className="grid xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {clientData.map((item,index) => {
                        return(
                            <div className="group text-center" key={index}>
                                <div className="relative inline-block mx-auto max-h-[208px] max-w-[208px] rounded-full overflow-hidden shadow dark:shadow-gray-700">
                                    <img src={item.image} className="" alt=""/>
                                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black max-h-[208px] max-w-[208px] rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>

                                    <ul className="list-none absolute start-0 end-0 -bottom-20 group-hover:bottom-5 duration-500 space-x-1">
                                        <li className="inline"><Link to="#" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><Icon.Facebook className="h-4 w-4"/></Link></li>
                                        <li className="inline"><Link to="#" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><Icon.Instagram className="h-4 w-4"/></Link></li>
                                        <li className="inline"><Link to="#" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><Icon.Linkedin className="h-4 w-4"/></Link></li>
                                    </ul>
                                </div>

                                <div className="content mt-3">
                                    <Link to="" className="text-lg font-semibold hover:text-indigo-600 duration-500">{item.name}</Link>
                                    <p className="text-slate-400">{item.title}</p>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>
            </div>
        </div>
    )
}